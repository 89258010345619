import { render, staticRenderFns } from "./FormBuilder.vue?vue&type=template&id=574a4334&scoped=true&"
import script from "./FormBuilder.vue?vue&type=script&lang=ts&"
export * from "./FormBuilder.vue?vue&type=script&lang=ts&"
import style0 from "./FormBuilder.vue?vue&type=style&index=0&id=574a4334&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "574a4334",
  null
  
)

export default component.exports