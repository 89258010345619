import Vue from 'vue';

export default Vue.extend({

  data() {
    return {
      showBtn: false,
      width: window.innerWidth,
      showMenuModal: false,
      tagId: '',
      data: {},
    };
  },
  isPopup() {
    return true;
  },


  created() {

  },
  methods: {


    editAction() {
      const objectID = this.params.data.id;
      console.log(this.params);
      this.params.context.componentParent.editAction(objectID);
    },

    deleteAction() {
      const objectID = this.params.data.id;
      console.log(this.params);
      this.params.context.componentParent.deleteAction(objectID);
    },

  },
  template: `
      <div>

      <span
        @click="editAction"
      ref="buttonActionMenu"
       style="display: inline-block; font-size: 18px; color: rgb(66, 219, 192);  vertical-align: middle; line-height: normal;"
      class="material-icons cursor-pointer"
    >
   edit
    </span>

    <span
        @click="deleteAction"
      ref="buttonActionMenu"
       style="display: inline-block; font-size: 18px; margin-left: 10px; color: rgba(255,0,0);  vertical-align: middle; line-height: normal;"
      class="material-icons cursor-pointer"
    >
   delete
    </span>


    </div>
    `,
});
